import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AccountDetails, ValidateSucceededResponseObject } from '../types/types';
import {
  MembershipUpgradePlanForm,
  MembershipUpgradePlanFormStep,
  MembershipUpgradePlanPayParam,
} from './membership-upgrade-plan.models';
import { changedForm, setFormActiveStep, validateFormStep } from '../../modules/share/ngrx-helpers';
import { RequestError } from '../generic-errors';
import { FormGroupValue } from '../../modules/share/form.utils';
import { BillingForm } from '../../modules/share/billing-form';

export const MembershipUpgradePlan = createActionGroup({
  source: 'Membership Upgrade',
  events: {
    ...setFormActiveStep<MembershipUpgradePlanFormStep>(),
    ...validateFormStep<MembershipUpgradePlanFormStep>(),
    ...changedForm<MembershipUpgradePlanForm>(),
    'Update Level Form': props<{ level: string; rv: boolean }>(),
    'Update Billing Form': props<{ billing: FormGroupValue<BillingForm> }>(),
    'Validate Form Step': props<{
      validateStep: MembershipUpgradePlanFormStep;
      params: MembershipUpgradePlanPayParam;
    }>(),
    'Recost Validate': emptyProps(),
    'Recost Validate Succeeded': props<ValidateSucceededResponseObject>(),
    'Recost Validate Failed': props<{ error: RequestError }>(),
    'Validate Billing Info': emptyProps(),
    'Validate Billing Info Succeeded': emptyProps(),
    'Validate Billing Info Failed': emptyProps(),
    Pay: props<{ params: MembershipUpgradePlanPayParam }>(),
    'Pay Succeeded': emptyProps(),
    'Pay Failed': props<{ error: RequestError }>(),
    'Set Account Details': props<{ accountDetails: AccountDetails }>(),
    Reset: emptyProps(),
    'Remove Error': emptyProps(),
  },
});
