import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MembershipUpgradePlanForm } from '../../store/membership-upgrade-plan/membership-upgrade-plan.models';
import { BillingFormVm } from '../share/billing-form';

@Injectable({ providedIn: 'root' })
export class MembershipUpgradePlanVm {
  fb = inject(FormBuilder);
  billingFormVm = inject(BillingFormVm);
  formGroup = this.create();

  create() {
    return this.fb.group<MembershipUpgradePlanForm>({
      level: new FormControl('', { nonNullable: true }),
      rv: new FormControl(false, { nonNullable: true }),
      billing: this.billingFormVm.create(),
    });
  }
}
